require('../css/3-layouts/_home.scss');
require('slick-carousel');
//const $ = require('jquery');
require('../../node_modules/slick-carousel/slick/slick.css');

var prevArrow = '<a href="#" class="slick-prev"><i class="fas fa-chevron-left"></i></a>';
var nextArrow = '<a href="#" class="slick-next"><i class="fas fa-chevron-right"></i></a>';

$('.home-slider, .gallery-slider').slick({
    arrows: true,
    infinite: true,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    autoplay: true,
    autoplaySpeed: 3000
});